.sidenav {
  width: 250px;
  transition: width 0.3s ease-in-out;
  background-color: black;
  /* background-color: rgb(10, 25, 41); */
  padding-top: 1rem;
  position: relative;
  margin-top: 62px !important;
}

.sidenavClosed {
  composes: sidenav;
  transition: width 0.3s ease-in-out;
  width: 60px;
  padding-top: 1rem;
  background-color: rgb(10, 25, 41);
  margin-top: 58px !important;
}

.sideitem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  color: #efefef;
  text-decoration: none;
  font-size: 1.35rem;
  font-weight: 600;
}

.linkText {
  padding-left: 16px;
}

.linkTextClosed {
  composes: linkText;
  visibility: hidden;
}

.sideitem:hover {
  background-color: lightgray;
  color: #222;
}

.menuBtn {
  margin-bottom: 15px;
  color: #efefef;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding-right: 25px;
}
.foot_head {
  position: absolute;
  bottom: 0;
  right: 0;
  color: white;
}
